@import '../../common/scss/common.scss';

.salesWrapper {
    .tabContent {
        margin-top: 32px;

        .tabPane {
            grid-gap: 20px;
            grid-row-gap: 30px;

            .cardWrap {
                flex: 0 0 calc(50% - 10px);

                @media only screen and (max-width: 1148px) {
                    flex: 0 0 100%;
                }
            }
        }
    }
}

.text-black {
    color: #000 !important;
    font-weight: 500 !important;
}

.cardCrown.alt {
    background-color: transparent !important;
}

.addItemButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    position: absolute;
    bottom: 16px;
    right: 0;
    z-index: 60;
}

.addedItemListWrap {
}

.prescriptionImages {
    padding: 16px 0;
    border-bottom: 4px solid #ededed;

    .prescriptionImagesWrap {
        grid-gap: 12px;

        .prescriptionImage {
            max-width: 40px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.invoiceTable {
    border-collapse: collapse;
    table-layout: fixed; /* Ensures consistent column widths */
    font-size: 14px;
    border: 1px solid #d4d4d4;

    .tdWrap {
        overflow-wrap: break-word; /* Prevents content overflow */
    }

    th,
    td {
        padding: 8px;
        text-align: left;

        &:last-child {
            padding-right: 3px;
            text-align: right;
        }
    }

    tr {
        border-bottom: 1px solid #d4d4d4;
    }

    thead {
        background-color: #f2f2f2;
    }

    &.withScroll {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        min-width: 100%;

        scrollbar-width: thin;

        &::-webkit-scrollbar {
            height: 8px;
            background-color: #fcebe3;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #312e2e;
            border-radius: 4px;
        }

        // &:hover {
        //     scrollbar-width: thin;
        // }

        // &:hover::-webkit-scrollbar {
        //     height: 8px;
        //     background-color: #FCEBE3;
        // }

        // &:hover::-webkit-scrollbar-thumb {
        //     background-color: #312e2e;
        //     border-radius: 4px;
        // }
    }
}

.wizardFieldset {
    position: relative;

    .mask {
        position: absolute;
        background-color: rgba(#000, 0.02);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);

        .maskData {
            margin-top: 50%;
        }
    }
}
.viewProductWrap {

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#ffffff, 0.2);
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
        display: grid;
        place-items: center;
        text-align: center;
        p {
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.viewProductHeader {
    .ProductUniqueTag {
        display: flex;
        align-items: center;
        gap: 1rem;

        .cardCrown {
            position: static;
            font-size: 12px;
            padding: 0px 8px;
        }
    }
}

.table {
    &.onlineSales {
        thead > tr > td {
            background-color: #dedede;
            text-align: left;
            padding: 12px 16px;
            color: unset;

            &:nth-child(1) {
                width: 60%;
                // color: #212121;
            }

            &:not(:first-child) {
                text-align: right;
            }

            // &:nth-child(2) {
            //     text-align: right;
            // }

            // &:last-child {
            //     text-align: right;
            // }
        }

        tbody > tr > td {
            padding: 16px 16px;
            color: $primary;
            border-bottom: 1px solid #d4d4dd;

            &:nth-child(1) {
                width: 50%;
                // color: #212121;
            }

            &:last-child {
                font-weight: 600;
            }

            &:not(:first-child) {
                text-align: right;
            }
        }
    }
}

.contactDetailsRow {
    .contactDetailsRowLogo {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 8px;
    }

    .productSupplierName.number {
        border: none !important;
        font-size: 16px;
        padding: 0 !important;
        margin: 0 !important;
    }
}

.print * {
    font-family: "Roboto Mono" !important;
    font-weight: 700 !important;

    // .productSupplierAddress {
    //     font-weight: 400 !important;
    // }

    &.productPrice {
        tbody > tr > td {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            padding: 3px 0;
            text-align: right;

            &:first-child {
                text-align: left;
            }

            .border {
                border: 4px solid #ededed;
                margin: 12px 0;
            }

            &.strong {
                font-weight: 600;
            }
        }
    }

    .productDescriptionWrap .disclaimerNotes  {
        border-top: 4px solid #ededed;
        margin-top: 16px;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }
}
